html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 14px;
	// font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
  line-height: 1;
  margin: 0;
	padding: 0;
	min-width:1260px;
}
ol, ul {
	list-style: none;
	margin:0;
	padding:0;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.btn{
	cursor: pointer;
}
.btn:active{
	opacity: 0.5;
}

.container{
	width: 1260px;
	min-height: calc(100vh - 146px);
    margin: 0 auto;
}

.table-link{
	span{
		display: inline-block;
		margin: 0 10px;
		color:#3399FF;
		text-decoration: underline;
		cursor: pointer;
	}
	.del{
		color:#FA6D41
	}
}

.ant-checkbox-wrapper{
	margin-left: 0 !important;
	margin-right: 8px !important;
	margin-bottom: 5px !important;
}

.hover-active{
	cursor: pointer;
	transition: all 0.3s;
	&:hover{
		color: #222;
	}
}

.ant-breadcrumb-link{
	color: #2A89FF;
}
.ant-breadcrumb-separator{
	color: #999;
}
.ant-table-column-title{
	color: #999;
}
.ant-menu-dark, .ant-menu-dark .ant-menu-sub{
	background-color: #0c2171;
}
.ant-menu-item-selected{
	background-color: transparent !important;
}